import { Flex, Group, Text, Divider } from "@mantine/core";

const Footer = ({ classes }) => {
  return (
    <Flex
      mih={50}
      bg="#F6F6F6"
      gap="md"
      justify="flex-start"
      align="center"
      direction="row"
      className={classes.footer}
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      <Group px="10%" noWrap>
        <Text fz="sm" lh={1}>
          Soporte o ayuda
        </Text>
        <Divider orientation="vertical" />
        <Text fz="sm" lh={1}>
          Términos de uso
        </Text>
        <Divider orientation="vertical" />
        <Text fz="sm" lh={1}>
          Políticas de privacidad
        </Text>
      </Group>
    </Flex>
  );
};

export default Footer;
